import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

export default class ExampleComponent extends Component {

  state = {
    focused: false
  }

  static propTypes = {
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    autoComplete: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    hideBar: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    staticLabel: PropTypes.bool,
    labelZoom: PropTypes.bool
  }

  toggleFocused = () => {
    this.setState({ focused: !this.state.focused })
  }

  inputStyle = () => {
    const { activeColor = '#007BFF', color = '#999', hideBar, disabled } = this.props
    const { focused } = this.state
    const borderWidth = focused ? 2 : 1
    if (hideBar || disabled) return {}

    return {
      borderBottom: `${!focused ? color : activeColor} ${borderWidth}px solid`
    }
  }

  renderLabel = (inputValue) => {
    const { labelZoom, staticLabel, placeholder, activeColor = '#007BFF', color = '#999' } = this.props

    const { focused } = this.state
    const labelZoomObj = (labelZoom && !inputValue) ? { fontSize: '19px', top: '7px' } : {}

    if (!staticLabel) {
      return (
        <label
          style={focused ? { ...{ color: activeColor }, ...labelZoomObj } : { color }}
          className={`${styles['float-label']}  ${styles['label']}  ${inputValue && styles['float-active']}`}
        > {placeholder}
        </label>
      )
    }
    return <label className={`${styles['float-label']}  ${styles['float-active']}  ${styles['label']}`} style={focused ? { color: activeColor } : { color }}>{placeholder}</label>
  }

  render() {
    var { options, onKeyPress, onChange, onBlur, onFocus, type = 'text', value = '', name, disabled, autoComplete } = { ...this.props, ...this.props.input }

    return (
      <div className={styles['float-group']}>
        <input
          {...options}
          autoComplete={autoComplete}
          disabled={disabled}
          className={styles['float-control']}
          onKeyPress={onKeyPress}
          onFocus={
            () => {
              this.toggleFocused()
              if (onFocus) onFocus()
            }}
          onBlur={
            () => {
              this.toggleFocused()
              if (onBlur) onBlur()
            }}
          onChange={onChange}
          type={type}
          name={name}
          value={value}
          style={this.inputStyle()}
        />
        <span className={styles['form-highlight']} />
        {this.renderLabel(value)}
      </div>
    )
  }
}
